const Header = () => {
  return (
    <header className="  header ">
      <div className="d-flex justify-content-around align-items-center">
        <img src="./logo.jpeg" className="logo" />
      </div>

      <div className="header_text">
        <div className="ar_header_text warnning_text_content">
          محطة الصبية لتوليد القوى الكهربائية وتقطير المياه
        </div>
        <div className="en_header_text warnning_text_content">
          SUBIYA POWER GENERATION AND WATER AND DISTLATION PLANT
        </div>
      </div>
    </header>
  );
};

export { Header };
